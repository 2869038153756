import {
    Injectable,
    EventEmitter
}                 from '@angular/core';
import { Router } from '@angular/router';
import { Sessao } from './sessao';
import { Api }    from './api';
import { map }    from 'rxjs/operators';

@Injectable()
export class Auth{

    public userIsLoged = new EventEmitter<boolean>();

    constructor(
        private router: Router,
        private api: Api
    ){}

    public isLoged(){

        const token = Sessao.getObjectDB( '_token_' );
        return this.api.checkSession({ token:token }).pipe(map(res => {
            this.userIsLoged.emit( res.auth );
            return res.auth;
        }));
    }
}
