import { Injectable, NgZone } from '@angular/core';
import { HttpClient as Http, HttpHeaders as Headers } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConstantService } from './constant.service';
import { Sessao } from './sessao';

@Injectable()
export class Api {

  private headersUnsave = new Headers({ 'Content-Type': 'application/json' });
  private headersSave: any = null

  constructor(
    private http: Http,
    private zone: NgZone
  ) { }

  public setToken(token: string) {
    Sessao.setObjectDB('_token_', token);
  }

  public getToken() {
    let token = Sessao.getObjectDB('_token_');
    this.headersSave = new Headers({
      'Content-Type': 'application/json',
      'x-access-token-by-furious': `${token}`
    });
    return this.headersSave;
  }

  public reverse(latlng: any): Observable<any> {

    const _url = `${ConstantService.endPointNomin()}/apiv1/geocode/${latlng.lng}/${latlng.lat}`;
    return this.http.get(_url, { headers: this.headersUnsave });
  }

  public login(_user): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/signin`;
    return this.http.post(_url, JSON.stringify(_user), { headers: this.headersUnsave });
  }

  public createUser(_user): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/signup`;
    return this.http.post(_url, JSON.stringify(_user), { headers: this.headersUnsave });
  }

  public getGroup(): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/group`;
    return this.http.get(_url, { headers: this.getToken() });
  }

  public createGroup(_group): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/group`;
    return this.http.post(_url, JSON.stringify(_group), { headers: this.getToken() });
  }

  public createApp(_app): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/application`;
    return this.http.post(_url, JSON.stringify(_app), { headers: this.getToken() });
  }

  public getApplication(): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/application`;
    return this.http.get(_url, { headers: this.getToken() });
  }

  public getVehicles(): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/vehicles`;
    return this.http.get(_url, { headers: this.getToken() });
  }

  public getAllVehicles(): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/vehicles/nofilter`;
    return this.http.get(_url, { headers: this.getToken() });
  }

  public getLines(): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/linhas`;
    return this.http.get(_url, { headers: this.getToken() });
  }

  public getVeiculoAlocado(_ids): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/veiculoInLinha`;
    return this.http.post(_url, JSON.stringify(_ids), { headers: this.getToken() });
  }

  public getVehicle(_ids): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/positions`;
    return this.http.post(_url, JSON.stringify(_ids), { headers: this.getToken() });
  }

  public getPosition(_ids): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/positions`;
    return this.http.post(_url, JSON.stringify(_ids), { headers: this.getToken() });
  }

  public getPositions(): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/positions/all`;
    return this.http.get(_url, { headers: this.getToken() });
  }

  public getSocketPositions(): Observable<any> {

    const _url = `${ConstantService.endPointSocket()}positions`;
    return this.http.get(_url);
  }

  public loadPosition(_ids): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/positions/load`;
    return this.http.post(_url, JSON.stringify(_ids), { headers: this.getToken() });
  }

  public loadPositionAlocado(_ids): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/positions/loadAlocados`;
    return this.http.post(_url, JSON.stringify(_ids), { headers: this.getToken() });
  }

  public getAllLoadPos(_params): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/list_pos`;
    return this.http.post(_url, JSON.stringify(_params), { headers: this.getToken() });
  }

  public getAllLoadPosSnap(_params): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/list_pos_snap`;
    return this.http.post(_url, JSON.stringify(_params), { headers: this.getToken() });
  }

  public createAutoGeofence(_params): Observable<any> {
    const _url = `${ConstantService.endPoint()}/apiv1/pos_snap_to_fence`;
    return this.http.post(_url, JSON.stringify(_params), { headers: this.getToken() });
  }

  public getAllLoadPosLineSnap(_params): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/list_pos_linha_snap`;
    return this.http.post(_url, JSON.stringify(_params), { headers: this.getToken() });
  }

  public getPositionNearFence(_params): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/positions/near_fence`;
    return this.http.post(_url, JSON.stringify(_params), { headers: this.getToken() });
  }

  public getGeoReverse(_id): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/reverse`;
    return this.http.post(_url, JSON.stringify(_id), { headers: this.getToken() });
  }

  public createCerca(_cerca): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/geofence`;
    return this.http.post(_url, JSON.stringify(_cerca), { headers: this.getToken() });
  }

  public updateCerca(_cerca): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/updategeofence`;
    return this.http.post(_url, JSON.stringify(_cerca), { headers: this.getToken() });
  }

  public getCercaTrocaLinha(_cerca): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/geofence/trocalinha`;
    return this.http.post(_url, JSON.stringify(_cerca), { headers: this.getToken() });
  }

  public getCercas(): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/geofences`;
    return this.http.get(_url, { headers: this.getToken() });
  }

  public getCerca(_type): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/geofence/find`;
    return this.http.post(_url, JSON.stringify(_type), { headers: this.getToken() });
  }

  public getSizeLine(_linhas): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/geofence/find_size_lines`;
    return this.http.post(_url, JSON.stringify(_linhas), { headers: this.getToken() });
  }

  public createAlocacao(_cerca): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/geofence`;
    return this.http.post(_url, JSON.stringify(_cerca), { headers: this.getToken() });
  }

  public updateAlocacao(_cerca): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/updategeofence`;
    return this.http.post(_url, JSON.stringify(_cerca), { headers: this.getToken() });
  }

  public getAlocacao(_id): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/alocacao`;
    return this.http.post(_url, JSON.stringify(_id), { headers: this.getToken() });
  }

  public getAlocacaoVeiculo(_id): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/alocacaoVeiculo`;
    return this.http.post(_url, JSON.stringify(_id), { headers: this.getToken() });
  }

  public getAlocacaoLine(_info): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/alocacao/linha`;
    return this.http.post(_url, JSON.stringify(_info), { headers: this.getToken() });
  }

  public getAlocacaoVehicle(_info: any): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/alocacao/veiculo`;
    return this.http.post(_url, JSON.stringify(_info), { headers: this.getToken() });
  }

  public getAlocacaoPorLinha(_info: any): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/alocacao/porlinha`;
    return this.http.post(_url, JSON.stringify(_info), { headers: this.getToken() });
  }

  public makeAlocacaoTemp(_info: any): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/alocacao/createtemp`;
    return this.http.post(_url, JSON.stringify(_info), { headers: this.getToken() });
  }

  public cancelAlocacao(_info: any): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/alocacao/cancelalocacao`;
    return this.http.post(_url, JSON.stringify(_info), { headers: this.getToken() });
  }

  public nearAlocacao(_info: any): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/alocacao/proximidade`;
    return this.http.post(_url, JSON.stringify(_info), { headers: this.getToken() });
  }

  public getEvGeofence(_id): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/evgeofence/find`;
    return this.http.post(_url, JSON.stringify(_id), { headers: this.getToken() });
  }

  public checkSession(_token): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/session/check`;
    return this.http.post(_url, JSON.stringify(_token), { headers: this.headersUnsave });
  }

  public getTelemetria(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/eventos/telemetria`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public getStarts(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/partidas/all`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public addStarts(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/partidas/add`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public delStarts(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/partidas/del`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public getStartsScales(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/partidas/escala`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public getScales(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/escalas/all`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public addScale(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/escalas/add`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public delScale(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/escalas/del`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public getProgramations(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/programacoes/all`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public addProgramations(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/programacoes/add`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public delProgramations(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/programacoes/del`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public editProgramations(_data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/programacoes/edit`;
    return this.http.post(_url, JSON.stringify(_data), { headers: this.getToken() });
  }

  public getEventsInRoad(data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/eventos/getinroad`;
    return this.http.post(_url, JSON.stringify(data), { headers: this.getToken() });
  }

  public getOccorrencInRoad(data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/eventos/getoccorinroad`;
    return this.http.post(_url, JSON.stringify(data), { headers: this.getToken() });
  }

  public removeOccorrence(data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/eventos/ocorrencia/remove`;
    return this.http.post(_url, JSON.stringify(data), { headers: this.getToken() });
  }

  public getOutLines(data): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/eventos/getoutline`;
    return this.http.post(_url, JSON.stringify(data), { headers: this.getToken() });
  }

  public getPedagio(data: any): Observable<any> {

    const _url = `${ConstantService.endPoint()}/apiv1/control/poi`;
    return this.http.post(_url, JSON.stringify(data), { headers: this.getToken() });
  }

  public getEventStream(): Observable<any> {
    const _url = `${ConstantService.endPointSocket()}positions`;
    return new Observable(observer => {
      const eventSource = new EventSource(_url);

      eventSource.onmessage = event => {
        this.zone.run(() => {
          observer.next(JSON.parse(event.data));
        });
      };

      eventSource.onerror = error => {
        this.zone.run(() => {
          observer.error(error);
        });
      };

      return () => {
        eventSource.close();
      };
    });
  }
}
