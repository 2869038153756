import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

//const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  public exportAsExcelFile(json: any[], excelFileName: string, type: any): void {

    if (type.BKB == '001') {

      const worksheet = XLSX.utils.aoa_to_sheet([
        ["IBTECH Consultoria LTDA"],
        ["endereço: Rua Dina Sfat - Fragoso - Magé, CEP 25935-168"],
        ["telefone: (21) 97926-9660 / e-mail: brunoalves.dev@gmail.com.br"],
        ["Auto Viação Reginas"],
        [`motorista: ${type.Motorista} | linha: ${type.Linha} | veículo: ${type.Veículo}`]
      ]);

      worksheet.A1.s = this.styleCells('A1');
      worksheet.A2.s = this.styleCells('A2');
      worksheet.A3.s = this.styleCells('A3');
      worksheet.A4.s = this.styleCells('A4');
      worksheet.A5.s = this.styleCells('A5');

      let A1 = { s: { c: 0, r: 0 }, e: { c: 3, r: 0 } };
      let A2 = { s: { c: 0, r: 1 }, e: { c: 3, r: 1 } };
      let A3 = { s: { c: 0, r: 2 }, e: { c: 3, r: 2 } };
      let A4 = { s: { c: 0, r: 3 }, e: { c: 3, r: 3 } };
      let A5 = { s: { c: 0, r: 4 }, e: { c: 3, r: 4 } };

      worksheet["!merges"] = [
        A1,
        A2,
        A3,
        A4,
        A5
      ];

      let wscols = [
        { wch: 50 },
        { wch: 10 },
        { wch: 10 },
        { wch: 20 }
      ];

      worksheet['!cols'] = wscols;
      worksheet['!cellStyles']

      XLSX.utils.sheet_add_json(worksheet, json, { origin: "A6" })
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] }
      this.saveAsExcelFile(workbook, excelFileName)

    } else if (type.BKB == '010') {

      const worksheet = XLSX.utils.aoa_to_sheet([
        ["IBTECH Consultoria LTDA"],
        ["endereço: Rua Dina Sfat - Fragoso - Magé, CEP 25935-168"],
        ["telefone: (21) 97926-9660 / e-mail: brunoalves.dev@gmail.com.br"],
        ["Auto Viação Reginas"],
        [""]
      ]);

      worksheet.A1.s = this.styleCells('A1');
      worksheet.A2.s = this.styleCells('A2');
      worksheet.A3.s = this.styleCells('A3');
      worksheet.A4.s = this.styleCells('A4');
      worksheet.A5.s = this.styleCells('A5');

      let A1 = { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } };
      let A2 = { s: { c: 0, r: 1 }, e: { c: 2, r: 1 } };
      let A3 = { s: { c: 0, r: 2 }, e: { c: 2, r: 2 } };
      let A4 = { s: { c: 0, r: 3 }, e: { c: 2, r: 3 } };
      let A5 = { s: { c: 0, r: 4 }, e: { c: 2, r: 4 } };

      worksheet["!merges"] = [
        A1,
        A2,
        A3,
        A4,
        A5
      ];

      let wscols = [
        { wch: 35 },
        { wch: 30 },
        { wch: 20 },
        //{wch:20}
      ];

      worksheet['!cols'] = wscols;
      worksheet['!cellStyles']

      XLSX.utils.sheet_add_json(worksheet, json, { origin: "A6" })
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] }
      this.saveAsExcelFile(workbook, excelFileName)

    } else {

      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] }
      this.saveAsExcelFile(workbook, excelFileName)
    }
  }

  private styleCells(celName: string) {

    const cellStyle = {

      'A1': {
        alignment: {
          wrapText: true,
          vertical: 'center',
          horizontal: 'center'
        },
        border: {
          //top: { style: 'medium', color: '00000000' },
          //left: { style: 'medium', color: '00000000' },
          right: { style: 'medium', color: '00000000' }
        },
        font: {
          bold: true,
          sz: 11,
          color: { rgb: 'FFFFFF' }
        },
        fill: {
          patternType: 'solid',
          fgColor: { theme: "1", tint: "-0.25", rgb: 'f2842a' },
          bgColor: { indexed: 64 }
        }
      },
      'A2': {
        alignment: {
          wrapText: true,
          vertical: 'center',
          horizontal: 'top'
        },
        border: {
          //left: { style: 'medium', color: '00000000' },
          right: { style: 'medium', color: '00000000' }
        },
        font: {
          bold: false,
          sz: 9,
          italic: true
        }
      },
      'A3': {
        alignment: {
          wrapText: true,
          vertical: 'center',
          horizontal: 'top'
        },
        border: {
          //left: { style: 'medium', color: '00000000' },
          right: { style: 'medium', color: '00000000' }
        },
        font: {
          bold: false,
          sz: 9,
          italic: true
        }
      },
      'A4': {
        alignment: {
          wrapText: true,
          vertical: 'center',
          horizontal: 'top'
        },
        border: {
          //left: { style: 'medium', color: '00000000' },
          right: { style: 'medium', color: '00000000' }
        },
        font: {
          bold: false,
          sz: 9,
          italic: true
        }
      },
      'A5': {
        alignment: {
          wrapText: true,
          vertical: 'center',
          horizontal: 'top'
        },
        border: {
          bottom: { style: 'medium', color: '00000000' },
          //left: { style: 'medium', color: '00000000' },
          right: { style: 'medium', color: '00000000' }
        },
        font: {
          bold: true,
          sz: 8,
          italic: true
        }
      }
    };
    return cellStyle[celName];
  }

  private saveAsExcelFile(workbook: any, fileName: string): void {

    XLSX.writeFile(workbook, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)

    /*const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);*/
  }
}
