import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from '@angular/common/http';

import { JwtInterceptor } from './jwt.interceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { Sessao } from './providers/sessao';
import { Api } from './providers/api';
import { Auth } from './providers/auth';
import { Toggle } from './providers/toggle';
import { Leaflet } from './providers/leaflet';
import { Icon, DateMode, Util } from './providers/util';
import { Baloon } from './providers/util';
import { ConstantService } from './providers/constant.service';
import { ExcelService } from './providers/excel.service';
import { AuthGuard } from './guards/auth.guard';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    FontAwesomeModule,
  ],
  providers: [
    Sessao,
    Api,
    Auth,
    Toggle,
    Leaflet,
    Icon,
    Baloon,
    ExcelService,
    ConstantService,
    DateMode,
    Util,
    AuthGuard,
    Title,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
