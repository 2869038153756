import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class ConstantService {

  private static config: any = {
    endpoint_dev: 'http://ec2-44-203-218-79.compute-1.amazonaws.com/frontend',
    endPoint_prod: 'http://ec2-44-203-218-79.compute-1.amazonaws.com/frontend',
    endpoint_cache: 'http://ec2-44-221-206-167.compute-1.amazonaws.com/cacheaddress',
    endpoint_socket_prod: 'http://ec2-44-221-206-167.compute-1.amazonaws.com/socket/apiv1/',
    endpoint_socket_dev: 'http://ec2-44-221-206-167.compute-1.amazonaws.com/socket/apiv1/',
    corporateName: 'Auto Viação Reginas LTDA',
  };
  /*
    private static config: any = {
      endpoint_dev: 'http://127.0.0.1:8085/frontend',
      endPoint_prod: 'http://127.0.0.1:8085/frontend',
      endpoint_cache: 'http://ec2-44-221-206-167.compute-1.amazonaws.com/cacheaddress',
      endpoint_socket_prod: 'http://127.0.0.1/socket/apiv1/',
      endpoint_socket_dev: 'http://127.0.0.1/socket/apiv1/',
      corporateName: 'Auto Viação Reginas LTDA',
    };
  */
  public static endPoint(): string {
    if (environment.production) {
      return this.config.endPoint_prod;
    } else {
      return this.config.endpoint_dev;
    }
  }
  public static corporate(): string {
    return this.config.corporateName;
  }
  public static endPointNomin(): string {
    return this.config.endpoint_cache;
  }
  public static endPointSocket(): string {
    if (environment.production) {
      return this.config.endpoint_socket_prod;
    } else {
      return this.config.endpoint_socket_dev;
    }
  }
}
