import { Injectable } from '@angular/core';

import { Api } from './api';
import { Icon } from './util';

import 'moment/locale/pt-br';
import * as m from 'moment-timezone';

declare var L: any;
declare var $: any;

@Injectable()
export class Leaflet {

  constructor(
    private api: Api,
    private _icon: Icon
  ) {

  }

  private _configs: any = {
    empresa: '01',
    nome: 'Reginas',
    coordenadas: {
      lat: -22.778213541084323,
      lng: -43.327318802475936
    }
  };

  private color: Array<any> = [

    "#fe2508",
    "#11ff40",
    "#0e72f6",
    "#991c06",
    "#f49152",
    "#00730a",
    "#5a0b99",
    "#efff3c",
    "#3313a6"
  ];

  private _map: any;
  private playback: any = null;
  private playOptn: any = {

    sliderControl: false,
    playControl: false,
    dateControl: false,
    popups: true,
    //orientIcons: true,
    tracksLayer: false,
    maxInterpolationTime: 1 * 60 * 1000,
    marker: (featureData: any) => {

      if (!featureData.bbox) {

        let Icon = this._icon.getIconWithParams({

          speed: 0,
          heading: '0',
          color_seta: '#2cd41e',
          color_inter: '#0c0770',
          key: { key: 'onibus', text: '' },
          url: "#fff",
          follow: false
        });

        let _mico = new Icon({ labelText: '' });

        return {
          icon: _mico,
          //message: html,
          getPopup: (featureData) => {
            return '';
          }
        };

      } else {

        let Icon = this._icon.getIconWithParams({

          speed: 0,
          heading: '0',
          color_seta: '#2cd41e',
          color_inter: '#0c0770',
          key: { key: 'onibus', text: featureData.bbox },
          url: "#fff",
          follow: false
        });

        let _mico = new Icon({ labelText: featureData.bbox });
        let html = '';

        if (!featureData.cbox) {

          html = `${featureData.bbox || ""}`;

        } else {

          html = `
						<div center class="ballon-vehicle">

							<div class="ballon-header">-=${featureData.bbox || ""}=-</div>

							<div class="row show-grid">
								<div class="col-3">
									MOTORISTA:
								</div>
								<div class="col-9">
									${featureData.cbox.nome_motorista || '-- --'}
								</div>
							</div>

							<div class="row show-grid">
								<div class="col-3">
									PEGADA:
								</div>
								<div class="col-9">
									${featureData.cbox.pegada_motorista || '-- --'}
								</div>
							</div>

							<div class="row show-grid">
								<div class="col-3">
									LARGADA:
								</div>
								<div class="col-9">
									${featureData.cbox.largada_motorista || '-- --'}
								</div>
							</div>

							<div class="row show-grid">
								<div class="col-3">
									TURNO / SITU:
								</div>
								<div class="col-9">
									${featureData.cbox.turno || '-- --'} / Alocado
								</div>
							</div>

							<div class="row show-grid">
								<div class="col-3">
									LINHA:
								</div>
								<div class="col-9">
									${featureData.dbox.descricao || '-- --'}
								</div>
							</div>

							<div class="row show-grid">
								<div class="col-3">
									GUIA:
								</div>
								<div class="col-9">
									${featureData.cbox.numero_guia || '-- --'}
								</div>
							</div>
						</div>`;
        }
        return {
          icon: _mico,
          //message: html,
          getPopup: (featureData) => {
            return html;
          }
        };
      }
    }
  };

  public loadMap(): any {
    return this._map;
  }

  public setMap(mapContainer: string = 'map') {

    L.Map = L.Map.extend({
      openPopup: function (popup, latlng, options) {
        if (!(popup instanceof L.Popup)) {
          let content = popup;
          popup = new L.Popup(options).setContent(content);
        }
        if (latlng) {
          popup.setLatLng(latlng);
        }
        if (this.hasLayer(popup)) {
          return this;
        }
        this._popup = popup;
        return this.addLayer(popup);
      }
    });

    L.Map.include({
      'clearLayers': function () {
        this.eachLayer(function (layer) {
          this.removeLayer(layer);
        }, this);
      }
    });

    this._map = new L.Map('map', {
      center: new L.LatLng(this._configs.coordenadas.lat, this._configs.coordenadas.lng),
      zoom: 18,
      maxZoom: 18,
      closePopupOnClick: false,
      zoomControl: false,
      scrollWheelZoom: true,
      gestureHandling: true
    });

    //map.on('overlayadd', (e) => console.log(e));
    //map.on('baselayerchange', (e) => console.log(e));
  }

  public setCtrlPlay() {

    L.Playback = L.Playback || {};
    L.Playback.Control = L.Control.extend({

      _html: `
			<footer class="lp">
				<div class="d-flex justify-content-center">
					<div class="p-2">
						<a id="play-pause"><i id="play-pause-icon" class="fa fa-play fa-lg"></i></a>
					</div>
					<div class="p-2">
						<input id="time-slider" type="range" class="custom-range" min="null" max="null" step="null" >
				  	</div>
				  	<div class="p-2">
						<div id="clock-btn" class="clock">
							<i id="cursor-time"></i>
							<i id="cursor-date"></i>
						</div>
					</div>
					<div class="p-2">
						<input id="ckc-roteiro" type="checkbox">
						<span>Ver roteiro</span>
					</div>
				  	<div class="p-2">
					  	<a id="speed-btn" onclick="$('.menususp').toggle()">
							<i class="fa fa-dashboard fa-lg"></i> <p><span id="speed-icon-val" class="speed">1</span>x<p>
					  	</a>
					<div class="menususp">
						<input id="speed-input" class="span1 speed" type="text" value="1" disabled/>
						<input id="speed-slider" type="range" orient="vertical" min="1" max="9" step="1"/>
					</div>
				  </div>
				</div>
			</footer>
			`,

      initialize: function (playback) {

        this.playback = playback;
        playback.addCallback(this._clockCallback);
      },

      onAdd: function (map) {

        var html = this._html;
        $('#map').after(html);
        this._setup();
        return L.DomUtil.create('div');
      },

      _setup: function () {

        var self = this;
        var playback = this.playback;

        $('#play-pause').click(function () {

          if (playback.isPlaying() === false) {
            playback.start();
            $('#play-pause-icon').removeClass('fa-play');
            $('#play-pause-icon').addClass('fa-pause');
          } else {
            playback.stop();
            $('#play-pause-icon').removeClass('fa-pause');
            $('#play-pause-icon').addClass('fa-play');
          }
        });

        var startTime = playback.getStartTime();
        var endTime = playback.getEndTime();
        var step = playback.getTickLen();

        $('#cursor-date').html(L.Playback.Util.DateStr(startTime));
        $('#cursor-time').html(L.Playback.Util.TimeStr(startTime));
        $("#time-slider").attr({
          min: startTime,
          max: endTime,
          step: step
        });

        $("#time-slider").change(function () {
          var newv = $(this).val();
          playback.setCursor(newv);
          $('#cursor-time').val(newv.toString());
          $('#cursor-time-txt').html(new Date(newv).toString());
        });

        $('#speed-slider').attr({
          min: 1,
          max: 11,
          step: 1,
          value: self._speedToSliderVal(this.playback.getSpeed())
        });

        $('#speed-slider').change(function () {

          var newv = $(this).val();
          var speed = self._sliderValToSpeed(newv);
          var descr = self._speedToSliderVal(newv);

          playback.setSpeed(speed);

          $('.speed').html(descr).val(descr);
        });

        $('#speed-input').on('keyup', function (e) {
          var speed = parseFloat($('#speed-input').val());
          if (!speed) return;
          playback.setSpeed(speed);
          $('#speed-slider').val(this.speedToSliderVal(speed));
          $('#speed-icon-val').html(speed);
          if (e.keyCode === 13) {
            $('.speed-menu').dropdown('toggle');
          }
        });

        $('.dropdown-menu').on('click', function (e) {
          e.stopPropagation();
        });
      },

      _clockCallback: function (ms) {

        $('#cursor-date').html(L.Playback.Util.DateStr(ms));
        $('#cursor-time').html(L.Playback.Util.TimeStr(ms));
        $('#time-slider').val(ms);
      },

      _speedToSliderVal: function (speed) {
        if (parseInt(speed) == 11) return 'Hyp';
        return parseInt(speed);
      },

      _sliderValToSpeed: function (val) {
        if (val == 11) return -9;
        return parseInt(val);
      },

      _combineDateAndTime: function (date, time) {
        var yr = date.getFullYear();
        var mo = date.getMonth();
        var dy = date.getDate();
        // the calendar uses hour and the timepicker uses hours...
        var hr = time.hours || time.hour;
        if (time.meridian === 'PM' && hr !== 12) hr += 12;
        var min = time.minutes || time.minute;
        var sec = time.seconds || time.second;
        return new Date(yr, mo, dy, hr, min, sec).getTime();
      }
    });

    this.playback = new L.Playback(this._map, null, null, this.playOptn);

    var control = new L.Playback.Control(this.playback);

    control.addTo(this._map);

    return this.playback;
  }

  public setPlayback(geoJsonTrack: any): void {

    const array = [];
    const type = (Object.keys(geoJsonTrack).length > 1) ? "LineString" : "MultiPoint";

    Object.keys(geoJsonTrack).forEach(i => {

      let int = Math.floor(Math.random() * 8);
      geoJsonTrack[i].geometry.type = type;
      geoJsonTrack[i].color = this.color[int];
      array.push(geoJsonTrack[i]);
    });

    const layer_options = {};
    const _selfie = this;

    layer_options['pointToLayer'] = function (featureData, latlng) {

      let result = {
        radius: 5,
        color: '#1a0582'
      };

      if (featureData && featureData.properties && featureData.properties.path_options) {
        result = featureData.properties.path_options;
      }

      const marker = new L.CircleMarker(latlng, result);

      marker.on('click', function (ev) {

        _selfie._setClickPoint(latlng, featureData, this);
      });

      return marker;
    };

    this.playback['layer'] = new L.GeoJSON(array, layer_options);
    this.playback.setData(array);
    this.playback.setSpeed(1);

    var _self = this;

    this.playback['clearAllLayers'] = function () {

      _self.playback.stop();

      $('#play-pause-icon').removeClass('fa-pause');
      $('#play-pause-icon').addClass('fa-play');

      _self.playback.layer.eachLayer(function (layer) {

        _self._map.removeLayer(layer);
        $('#ckc-roteiro').prop("checked", false);
      });
    };

    $("#time-slider").attr({
      min: this.playback.getStartTime(),
      max: this.playback.getEndTime(),
      step: this.playback.getTickLen()
    });

    $('#ckc-roteiro').change(function () {
      var $input = $(this);
      var newv = $input.prop("checked");
      if (newv) {

        _self.playback.layer.eachLayer(function (layer) {
          _self._map.addLayer(layer);
        });

      } else {

        _self.playback.layer.eachLayer(function (layer) {
          _self._map.removeLayer(layer);
        });
      }
    });
    return this.playback;
  }

  public setControls(): void {

    const editableLayers = this.setOptionsMap();
    const options = {
      position: 'topright',
      draw: {
        polyline: true,
        polygon: false,
        circle: true,
        rectangle: false,
        marker: false,
        circlemarker: false
      },
      edit: {
        featureGroup: editableLayers,
        remove: true
      }
    };
    const drawControl = new L.Control.Draw(options);
    drawControl.addTo(this._map);
    return editableLayers;
  }

  public setOptionsMap() {

    const drawnItems = L.featureGroup().addTo(this._map);
    const here = {
      id: 'aUBcGCaf6rB0750Q8IJC',
      code: 'WUC2VK3BfnkrDkUgiGadUg'
    };
    const apiHere = ['base', 'traffic', 'aerial']
    const style = ['reduced.night', 'reduced.day', 'normal.day', 'normal.traffic.day', 'normal.traffic.night'];
    const version = ['1', '2', '3', '4'];
    const mapVersion = ['newest', 'hash'];
    const resource = ['maptile', 'alabeltile', 'blinetile', 'labeltile', 'linetile', 'lltile', 'mapnopttile', 'streettile', 'trucknopttile', 'truckonlytile', 'trucktile', 'xbasetile'];
    const hereTileUrl = `https://${version[1]}.${apiHere[0]}.maps.api.here.com/maptile/2.1/${resource[0]}/${mapVersion[0]}/${style[3]}/{z}/{x}/{y}/512/png?app_id=${here.id}&app_code=${here.code}&ppi=320`;
    const googleTraffic = `https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}`;
    const googleSattel = `https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}`;
    const esrI = `http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`;
    const subdomains = ['mt0', 'mt1', 'mt2', 'mt3'];
    const layers = {

      here: L.tileLayer(hereTileUrl),
      esri: L.tileLayer(esrI),
      satelite: L.tileLayer(googleSattel, { subdomains }),
      transito: L.tileLayer(googleTraffic, { subdomains })
    }
    /* SET MAP DEFAULT */
    this._map.addLayer(layers.esri);
    this._map['plus'] = (e: any) => {
      if (this.loadMap()._zoom < this.loadMap().getMaxZoom()) {
        this.loadMap().zoomIn(this.loadMap().options.zoomDelta * (e.shiftKey ? 3 : 1));
      }
    }
    this._map['minus'] = (e: any) => {
      if (this.loadMap()._zoom > this.loadMap().getMinZoom()) {
        this.loadMap().zoomOut(this.loadMap().options.zoomDelta * (e.shiftKey ? 3 : 1));
      }
    }
    this._map['here'] = () => {
      this.deleteMapLayer(layers)
      this.loadMap().addLayer(layers.here)
    }
    this._map['esri'] = () => {
      this.deleteMapLayer(layers)
      this.loadMap().addLayer(layers.esri)
    }
    this._map['google'] = () => {
      this.deleteMapLayer(layers)
      this.loadMap().addLayer(layers.transito)
    }
    return drawnItems;
  }

  private deleteMapLayer(groupLayer: any): void {
    if (this.loadMap().hasLayer(groupLayer.here)) {
      this.loadMap().removeLayer(groupLayer.here)
    } else if (this.loadMap().hasLayer(groupLayer.esri)) {
      this.loadMap().removeLayer(groupLayer.esri)
    } else if (this.loadMap().hasLayer(groupLayer.transito)) {
      this.loadMap().removeLayer(groupLayer.transito)
    }
  }

  public makeGeofence(dataLInha: any): any {

    const { rota = [], prefixo } = dataLInha;
    const [IDA, VOLTA] = rota;

    const geojsonFeature = [{
      "type": "Feature",
      "properties": {
        "id": IDA._id,
        "name": IDA.geofence.nome,
        "sentido": IDA.sentido,
        "popupContent": `${IDA.geofence.nome} - ${prefixo} - ${IDA.sentido}`
      },
      "geometry": IDA.geofence.geometry
    }, {
      "type": "Feature",
      "properties": {
        "id": VOLTA._id,
        "name": VOLTA.geofence.nome,
        "sentido": VOLTA.sentido,
        "popupContent": `${VOLTA.geofence.nome} - ${prefixo} - ${VOLTA.sentido}`
      },
      "geometry": VOLTA.geofence.geometry
    }];

    const geoObj = L.geoJSON(geojsonFeature, {
      style: (feature: any) => {
        switch (feature.properties.sentido) {
          case 'IDA': return { color: "#64e164", weight: 6, smoothFactor: 1, opacity: 0.6 };
          case 'VOLTA': return { color: "#f58c98", weight: 6, smoothFactor: 1, opacity: 0.6 };
        }
      },
      onEachFeature: this.onEachFeature
    });
    return geoObj;
  }

  public makeTerminal(dataLInha: any): any {

    const { rota = [], prefixo = null, numero_linha = null, geometry = null, descricao = null } = dataLInha;
    const geoObj = { 'IDA': null, 'VOLTA': null };

    if (!numero_linha) {

      return L.geoJSON({
        "type": "Feature",
        "properties": {
          "name": descricao,
          "sentido": "GARAGEM",
          "popupContent": `${descricao}`
        },
        "geometry": geometry
      }, {

        pointToLayer: (feature: any, latlng: any) => {

          return new L.Circle(latlng, {
            radius: 250,
            weight: 1,
            opacity: 1,
            fillOpacity: 0.8
          });
        },
        style: (feature: any) => {
          return { color: "#000", fillColor: "#420974" };
        },
        onEachFeature: this.onEachFeature
      });

    }
    rota.map(({ terminal = null, _id = null, sentido = null }) => {

      const geojsonFeatureIDA = [{
        "type": "Feature",
        "properties": {
          "id": _id,
          "name": terminal.nome,
          "sentido": sentido,
          "popupContent": `${terminal.nome} - ${prefixo} - ${sentido}`
        },
        "geometry": terminal.geometry
      }];

      geoObj[sentido] = L.geoJSON(geojsonFeatureIDA, {

        pointToLayer: (feature: any, latlng: any) => {

          return new L.Circle(latlng, {
            radius: 100,
            weight: 1,
            opacity: 1,
            fillOpacity: 0.8
          });
        },
        style: (feature: any) => {

          switch (feature.properties.sentido) {

            case 'IDA': return { color: "#000", fillColor: "#64e164" };
            case 'VOLTA': return { color: "#000", fillColor: "#f58c98" };
          }
        },
        onEachFeature: this.onEachFeature
      });
    });
    return geoObj;
  }

  private onEachFeature(feature: any, layer: any) {

    if (feature.properties && feature.properties.popupContent) {
      layer.bindPopup(feature.properties.popupContent);
    }
  }

  public arrayContainsArray(superset, subset) {

    let searchJson = JSON.stringify(subset);
    let arrJson = superset.map(JSON.stringify);

    return arrJson.indexOf(searchJson);
  }

  public clearRastreio(veiculosObj: any): void {
    Object.keys(veiculosObj).forEach(i => {
      if (!Object.keys(veiculosObj[i]).length) { } else {
        if (!!veiculosObj[i].rastreio) {
          if (!!Object.keys(veiculosObj[i].rastreio).length) {
            veiculosObj[i].rastreio.clearLayers();
            veiculosObj[i].rastreio = {};
          }
        }
      }
    });
  }

  public async rastrear(veiculo: any, timer: number, rastrear: boolean = true) {

    const {

      codigo,
      fugainicio = null,
      fugafinal = null,
      dateTime = null,

    } = veiculo;

    const params = {};
    const _dateTime = new Date(dateTime * 1000.0);

    if (rastrear) {

      params['carkey'] = [codigo];
      params['diference'] = timer;
      params['datetime'] = _dateTime;

    } else {

      params['carkey'] = [codigo];
      params['diference'] = timer;
      params['fugainicio'] = fugainicio;
      params['fugafinal'] = fugafinal;
    }

    const _self = this;
    const collection = await this.api.getAllLoadPos(params).toPromise();

    if (!collection) { alert('Não foram encontradas posições do periodo informado!') } else {

      const _rota = {
        "type": "FeatureCollection",
        "features": [{
          "type": "Feature",
          "geometry": {
            "type": "LineString",
            "coordinates": []
          },
          "properties": {
            "color": '#000000'
          }
        }, {
          "type": "Feature",
          "geometry": {
            "type": "MultiPoint",
            "coordinates": []
          },
          "properties": {
            "radius": 7,
            "color": '#000000'
          },
          "bbox": {}
        }]
      };
      const arrayA = [];
      const _time = [];
      const _speed = [];
      const _odometro = [];
      const _iginition = [];
      const _fuel = [];
      const _avl = [];
      const _heading = [];
      const _eventos = [];
      const _passagens = [];
      const _pagantes = [];
      const _gratuidades = [];
      const _rpm = [];
      const _temperatura = [];
      const _motorista = [];
      const _matricula = [];

      for (let item of collection) {

        const {
          avl = true,
          dateTime = 0,
          geometry: { coordinates: coord = [0, 0] } = {},
          positionInfo = [],
          ios: { ignitionState: ignition = 0 } = {},
          validador = {},
          telemetria = [],
          pagante = 0,
          gratuidade = 0,
          telemetry: {
            engine: { engineTemperature = 0 } = {},
            fuel: { fuelLevelTank1 = 0 } = {},
            odometer: { can: odometro = 0 } = {},
            speed: { can: velocity = 0, gps: velocityGps = 0 } = {},
            rpm: { can: rpm = 0 } = {},
            flag: { vehicleOperationTime: tempodeoperacao = 0 } = {}
          } = {},
          alocacao: { linha_numero: numerolinha = '--', matricula = '--', motorista = '--' } = {},

        } = item || {};

        const [{ directionDegrees = 0 }] = positionInfo;
        const timeroff = (dateTime * 1000.0);
        const velocityMain = (velocity > 0) ? velocity : velocityGps;

        arrayA.push([
          parseFloat(coord[1]),
          parseFloat(coord[0])
        ]);

        const arrayB = [
          parseFloat(coord[0]),
          parseFloat(coord[1])
        ];

        _rota.features[0].geometry.coordinates.push(arrayB);
        _rota.features[1].geometry.coordinates.push(arrayB);

        _time.push(timeroff);
        _speed.push(velocityMain);
        _odometro.push(odometro || 0);
        _iginition.push(ignition);
        _fuel.push(fuelLevelTank1);
        _avl.push(avl);
        _heading.push(directionDegrees);
        _eventos.push(telemetria);
        _passagens.push(validador);
        _pagantes.push(pagante);
        _gratuidades.push(gratuidade);
        _rpm.push(rpm);
        _temperatura.push(engineTemperature);
        _motorista.push(motorista);
        _matricula.push(matricula);
      }
      _rota.features[1].properties['time'] = _time;
      _rota.features[1].properties['speed'] = _speed;
      _rota.features[1].properties['odometro'] = _odometro;
      _rota.features[1].properties['ignition'] = _iginition;
      _rota.features[1].properties['fuel'] = _fuel;
      _rota.features[1].properties['isavl'] = _avl;
      _rota.features[1].properties['heading'] = _heading;
      _rota.features[1].properties['eventos'] = _eventos;
      _rota.features[1].properties['passagem'] = _passagens;
      _rota.features[1].properties['pagante'] = _pagantes;
      _rota.features[1].properties['gratuidade'] = _gratuidades;
      _rota.features[1].properties['rpm'] = _rpm;
      _rota.features[1].properties['temperatura'] = _temperatura;
      _rota.features[1].properties['motorista'] = _motorista;
      _rota.features[1].properties['matricula'] = _matricula;

      _rota.features[1]['bbox'] = codigo;

      if (!arrayA.length) { } else { this._map.fitBounds(arrayA) }

      return await L.geoJSON(_rota, {

        pointToLayer: (feat: any, ll: any) => {

          const key = _self.arrayContainsArray(feat.geometry.coordinates, [ll.lng, ll.lat]);
          const color1 = (!feat.properties.passagem[key]) ? null : '#b39706';
          const color2 = (!feat.properties.eventos[key].length) ? null : '#a600af';
          const color3 = '#1a0582';
          const heading = feat.properties.heading[key];

          const color = (color1) ? color1 : ((color2) ? color2 : color3);

          const Icon = this._icon.svgMarker({ heading, color });
          const _mico = new Icon();
          const marker = L.marker([ll.lat, ll.lng], { icon: _mico });

          marker.on('click', function (ev) {
            _self._setClickPoint(ll, feat, this)
          });

          return marker;
        }
      });
    }
  }

  public async _setClickPoint(latlng: any, feature: any, ev: any) {

    const endereco = await this.api.reverse(latlng).toPromise()

    const {
      bbox = null,
      properties: {
        speed = [],
        odometro = [],
        fuel = [],
        ignition = [],
        passagem = [],
        eventos = [],
        pagante = [],
        gratuidade = [],
        time = [],
        rpm = [],
        temperatura = [],
        motorista = [],
        matricula = [],
      },
      geometry: {
        coordinates = []
      }
    } = feature

    const key = this.arrayContainsArray(coordinates, [latlng.lng, latlng.lat])
    const { evento = null, tarifa = null, cardType = null } = passagem[key] || {}
    const event = eventos[key] || []
    const pagantes = pagante[key] || []
    const gratuidades = gratuidade[key] || []

    let _passagem = '';
    if (evento && evento == '03') {
      if (tarifa && tarifa == '0') {
        _passagem += `1 gratuidade`;
      } else {
        if (cardType == '0') {
          _passagem += `1 pagante venda a bordo`;
        } else {
          _passagem += `1 pagante cartão`;
        }
      }
    }
    const _eventData = {}
    const _eventos = []

    event.map(({ name, tip }) => _eventData[tip] = name)
    Object.keys(_eventData).map(i => _eventos.push(_eventData[i]))

    const kmOdometro = Math.floor(odometro[key] / 1000)
    const date = new Date(time[key])
    const _date = m(date).format('DD/MM/YYYY HH:mm:ss')
    const html = `
		<div class="MAPwrapperSmall">

			<div class="MAPtitle">
				<i class="fas fa-bus"></i> <strong>${bbox || ""}</strong>
				<div class="MAPstatus">
					<i class="fas fa-dot-circle"></i> ${(ignition[key] === 1) ? 'Ligado' : 'Desligado'}
				</div>
			</div>

			<div class="MAPline">
				<div class="MAPsvg">
					<i class="fas fa-portrait"></i>
				</div>
				${matricula[key] || ''} - ${motorista[key] || ''}
			</div>

			<div class="MAPline">
				<div class="MAPsvg">
					<i class="fas fa-clock"></i>
				</div>
				${_date || ''}
			</div>

			<div class="MAPline MAPgroup">

				<div class="MAPcol">
					<i class="fas fa-shipping-fast"></i> ${speed[key] || 0}km/h
					<span>Velocidade</span>
				</div>
				<div class="MAPcol">
					<i class="fas fa-tachometer-alt"></i> ${rpm[key] || 0}rpm
					<span>RPM</span>
				</div>

			</div>

			<div class="MAPline MAPgroup">
				<div class="MAPcol">
					<i class="fas fa-location-arrow"></i> ${kmOdometro || 0}km
					<span>Odômetro</span>
				</div>

				<div class="MAPcol">
					<i class="fas fa-temperature-high"></i> ${temperatura[key] || 0}˚
					<span>Temperatura</span>
				</div>
			</div>

			<div class="MAPline MAPcenter">
				<i class="fas fa-gas-pump"></i> Combustível
				<div class="MAPgas">
					<div style="width:${fuel[key] || 0}%">${fuel[key] || 0}%</div>
				</div>
			</div>

			<div class="MAPline">
				<div class="MAPsvg">
					<i class="fas fa-map-marker-alt"></i>
				</div>
				${endereco.address || ''}
			</div>

			<div class="MAPline">
				<div class="MAPsvg">
					<i class="fas fa-bell"></i>
				</div>
				${_eventos.join(', ')}
			</div>
    </div>`;

    console.log(endereco.address);
    ev.bindPopup(html).openPopup();
  }

  public renderLayers(model: any): void {

    const {

      sentidoida = null,
      sentidovolta = null,
      terminalida = null,
      terminalvolta = null,
      geofence = null,
      terminal = null

    } = model

    if (!sentidoida && geofence) {

      geofence.eachLayer((layer: any) => {
        if (layer.feature.properties.sentido === "IDA") {
          this._map.removeLayer(layer);
        }
      });

    } else if (sentidoida && geofence) {

      geofence.eachLayer((layer: any) => {
        if (layer.feature.properties.sentido === "IDA") {
          this._map.addLayer(layer);
        }
      });
    }

    if (!sentidovolta && geofence) {

      geofence.eachLayer((layer: any) => {
        if (layer.feature.properties.sentido === "VOLTA") {
          this._map.removeLayer(layer);
        }
      });

    } else if (sentidovolta && geofence) {

      geofence.eachLayer((layer: any) => {
        if (layer.feature.properties.sentido === "VOLTA") {
          this._map.addLayer(layer);
        }
      });
    }

    if (!terminalida && terminal) {

      terminal.eachLayer((layer: any) => {
        if (layer.feature.properties.sentido === "IDA") {
          this._map.removeLayer(layer);
        }
      });

    } else if (terminalida && terminal) {

      terminal.eachLayer((layer: any) => {
        if (layer.feature.properties.sentido === "IDA") {
          this._map.addLayer(layer);
        }
      });
    }

    if (!terminalvolta && terminal) {

      terminal.eachLayer((layer: any) => {
        if (layer.feature.properties.sentido === "VOLTA") {
          this._map.removeLayer(layer);
        }
      });

    } else if (terminalvolta && terminal) {

      terminal.eachLayer((layer: any) => {
        if (layer.feature.properties.sentido === "VOLTA") {
          this._map.addLayer(layer);
        }
      });
    }
  }

  public showGeofence(rota: any): any {

    const geojsonFeature = []
    const { rota: route = [] } = rota

    route.map(({ _id, nome, sentido, prefixo, geofence: { nome: geoName, geometry } }) => {
      geojsonFeature.push({
        "type": "Feature",
        "properties": {
          "id": _id,
          "name": geoName,
          "sentido": sentido,
          "popupContent": `${nome} - ${prefixo} - ${sentido}`
        },
        "geometry": geometry
      })
    })

    if (geojsonFeature.length > 0) {
      return L.geoJSON(geojsonFeature, {
        style: (feature: any) => {
          switch (feature.properties.sentido) {
            case 'IDA': return { color: "#0021ff", weight: 6, smoothFactor: 1, opacity: 0.6 };
            case 'VOLTA': return { color: "#ff1f00", weight: 6, smoothFactor: 1, opacity: 0.6 };
          }
        },
        onEachFeature: this.onEachFeature
      })
    }
    return null
  }

  public showTerminal(rota: any) {

    const { prefixo = null, rota: rotaData = [] } = rota || {}
    const geojsonFeature = []

    rotaData.map(({ _id, sentido, terminal: { geometry, nome: geoName } }) => {
      geojsonFeature.push({
        "type": "Feature",
        "properties": {
          "id": _id,
          "name": geoName,
          "sentido": sentido,
          "popupContent": `${geoName} - ${prefixo} - ${sentido}`
        },
        "geometry": geometry
      })
    })

    if (geojsonFeature.length > 0) {
      return L.geoJSON(geojsonFeature, {
        pointToLayer: (feature, latlng) => {
          return new L.Circle(latlng, {
            radius: 120,
            weight: 1,
            opacity: 1,
            fillOpacity: 0.8
          });
        },
        style: (feature) => {
          switch (feature.properties.sentido) {
            case 'IDA': return { color: "#000", fillColor: "#0021ff" };
            case 'VOLTA': return { color: "#000", fillColor: "#ff1f00" };
          }
        },
        onEachFeature: this.onEachFeature
      });
    }
    return null
  }

  public showBounds(obj: any) {
    const bound = []
    Object.keys(obj).map(e => {
      const coords = obj[e].marker.getLatLng()
      if (coords.lat === 0 || coords.lng === 0) { } else {
        bound.push(obj[e].marker.getLatLng())
      }
    })
    if (bound.length) {
      return this.loadMap().fitBounds(bound)
    }
    return null
  }
}
