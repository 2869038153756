import { NgModule }  from '@angular/core';
import {
    RouterModule,
    Routes,
    ExtraOptions
}                    from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const appRoutes: Routes = [

    { path: '',           loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule) },
    { path: 'maps',       loadChildren: () => import('./maps/maps.module').then(mod => mod.MapsModule), canActivate: [AuthGuard] },
    { path: 'telemetria', loadChildren: () => import('./telemetria-ranking/telemetria-ranking.module').then(mod => mod.TelemetriaRankingModule), canActivate: [AuthGuard] },
    { path: 'outroad',    loadChildren: () => import('./outroad/outroad.module').then(mod => mod.OutroadModule), canActivate: [AuthGuard] },
    { path: 'mapoutroad', loadChildren: () => import('./mapoutroad/mapoutroad.module').then(mod => mod.MapoutroadModule), canActivate: [AuthGuard] },
    { path: 'cerca',      loadChildren: () => import('./cerca/cerca.module').then(mod => mod.CercaModule), canActivate: [AuthGuard] },
    { path: 'poi',        loadChildren: () => import('./poi/poi.module').then(mod => mod.PoiModule), canActivate: [AuthGuard] },
    { path: 'devices',    loadChildren: () => import('./devices/device.module').then(mod => mod.DeviceModule) },
    { path: 'vehicles',   loadChildren: () => import('./vehicles/vehicle.module').then(mod => mod.VehicleModule) },
    //{ path: 'rastreio',   loadChildren: () => import('./rastreio/rastreio.module').then(mod => mod.RastreioModule), canActivate: [AuthGuard] },
    //{ path: 'pedagio',    loadChildren: () => import('./pedagio/pedagio.module').then(mod => mod.PedagioModule), canActivate: [AuthGuard] },
    //{ path: 'modulo',     loadChildren: () => import('./modulo/modulo.module').then(mod => mod.ModuloModule), canActivate: [AuthGuard] },
    //{ path: 'evento',     loadChildren: () => import('./evento/evento.module').then(mod => mod.EventoModule), canActivate: [AuthGuard] },
    //{ path: 'telemodule', loadChildren: () => import('./operacao/operacao.module').then(mod => mod.OperacaoModule), canActivate: [AuthGuard] },
];

const config: ExtraOptions = { useHash: true };

@NgModule({
    imports: [RouterModule.forRoot( appRoutes, config )],
    exports: [RouterModule]
})

export class AppRoutingModule {}
